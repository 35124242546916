import React from "react";

//Import Pages
import Nav from "./components/nav.js";
import Banner from "./img/banner.jpg";

import "./style/scroll.css";

function App() {
    return (
        <div class="break-words">
            <Nav />

            <div class="relative bg-indigo-800" id="homeBox">
                <div class="absolute inset-0">
                    <img class="w-full h-full object-cover" src={Banner} alt="" />
                    <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
                </div>
                <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                    <h1 class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
                        P. Overman Administratiekantoor B.V.
                    </h1>
                    <p class="mt-6 text-xl text-indigo-100 max-w-3xl"> P. Overman Administratiekantoor B.V. is een klein gezellig kantoor waar u
                        altijd terecht kunt voor al uw zakelijke zorgen.</p>
                </div>
            </div>
            <div class="flex">

                <div class="relative max-w-7xl mx-auto py-16 bg-white ">
                    <div class="relative px-4 sm:px-6 lg:px-8">
                        <div class="text-lg mx-auto">
                            <h1 class="text-2xl tracking-tight text-center font-bold text-indigo-600 lg:text-4xl xl:text-4xl">
                                Het adres voor al uw administratieve zaken
                            </h1>
                            <p class="mt-8 py-5 text-xl text-gray-500 leading-8">
                                P. Overman Administratiekantoor B.V. is een compleet en modern administratiekantoor in Almere.
                                <br />
                                Wij voeren praktisch alle werkzaamheden uit die elk administratiekantoor Almere ook doet,
                                echter
                                tegen het voordelige tarief.
                                <br />
                                Wij werken samen met <strong>belastingadvies-, advocaten kantoren</strong> uit de omgeving.
                            </p>
                        </div>
                        <div class="mt-6  prose-indigo prose-lg text-gray-500 mx-auto">
                            <p>
                                Wij bedienen vele ondernemers, <strong>ZZp'ers</strong>, <strong>MKB'ers</strong> of met een
                                <strong> B.V.</strong> binnen en buiten de regio Almere met vraagstukken op het gebied van
                                <strong> boekhouding</strong>,
                                <strong> loonadministratie</strong> en <strong>fiscale zaken</strong> etc..
                            </p>
                            <p>
                                Met een team van goed <strong>opgeleide</strong> en <strong>gemotiveerde </strong>
                                medewerkers zijn wij niet alleen uw
                                administratiekantoor, maar verzorgen we een tijdige afhandeling van uw belastingaangifte.
                                <br />
                                Als full service administratiekantoor helpen we ondernemers bij alle voorkomende
                                werkzaamheden.
                            </p>
                            <p>
                                Wij zorgen er tevens voor dat u te allen tijde toegang heeft tot uw cijfers zodat u altijd
                                inzicht
                                heeft in de financiële stand van zaken.
                                <br />
                                We werken onder andere met het bekende boekhoudpakket <strong>Exact Online</strong>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-4 max-w-7xl py-12 mx-auto" id="serviceBox">
                <div class="px-4">
                    <h1 class="text-2xl tracking-tight text-center font-bold text-indigo-600 lg:text-4xl xl:text-4xl">
                        Belastingen</h1>
                    <br />
                    <p>
                        Pro-actief zullen wij u met uw belastingzakenvan dienst zijn.
                        <br />
                        Wij proberen belasting betalen "leuker" te maken.
                    </p>
                    <br />
                    <p>
                        Wij regelen geschillen met belastingdienst, uitstel van aangiften, bezwaren en naturlijk alle soorten
                        belasting aangiften.
                    </p>
                    <br />
                    <p>
                        Toeslagen, uitkeringen etc.
                    </p>
                </div>
                <div class="px-4">
                    <h1 class="text-2xl tracking-tight text-center font-bold text-indigo-600 lg:text-4xl xl:text-4xl">
                        Payroll</h1>
                    <br />
                    <p>
                        Als ondernemer met personeel bent u verplicht een salarisadministratie te voeren. De wetgeving op dit
                        gebied is complex.
                        <br />
                        uw loon admnistratie / payroll is bij ons in goede handen.
                        <br />
                        Wij werken met WinSalar en het Online programma van Exact.
                    </p>
                    <br />
                    <p>
                        Zoekt u een betrouwbare partij om u volledig bij staan op gebied van personeels- en
                        salarisadministratie?
                    </p>
                    <br />
                    <p>
                        Neemt u dan gerust contact met ons op voor een vrijblijvend adviesgesprek.
                    </p>
                </div>
                <div class="px-2">
                    <h1 class="text-2xl tracking-tight text-center font-bold text-indigo-600 lg:text-4xl xl:text-4xl">
                        Advies</h1>
                    <br />
                    <p>
                        Goed bedrijfseconomisch advies is uitersbelangrijk!
                        <br />
                        Bij een verkeerde financiële keuze staat de toekomst van uw bedrijf op het spel.
                    </p>
                    <br />
                    <p>
                        Iemand, die met u meedenkt, die de financiële mogelijkheden van uw bedrijf goed kent, kan u helpen bij
                        ingewikkelde vragen.
                    </p>
                    <br />
                    <p>
                        Iemand die u tevens waarschuwt voor zaken waar u niet aan gedacht heeft.
                        <br />
                        Een frisse blik met kennis van zaken.
                    </p>
                </div>
            </div>

            <div class="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-3 gap-4 max-w-4xl mx-auto py-24">
                <div class="px-4">
                    <h1 class="text-2xl tracking-tight text-center font-bold text-indigo-600 lg:text-4xl xl:text-4xl">
                        Boekhouding</h1>
                    <br />
                    <p>
                        Ondernemingen van klein tot groot zoeken middelen om tijd en kosten te besparen.
                    </p>
                    <br />
                    <p>
                        Om u de aandacht te kunnen geven die u nodig heeft verwerken wij uw administratie ‘in de Cloud’. Wij
                        maken daarbij gebruik van geavanceerde software ExactOnline.
                    </p>
                    <br />
                    <p>
                        Wij maken heldere afspraken zodat u weet wat u van ons kunt verwachten en wat dat u zal kosten.
                        <br />
                        Met aandacht en betrokkenheid werken wij met u mee aan de groei van uw bedrijf.
                    </p>
                </div>
                <div class="px-4">
                    <h1 class="text-2xl tracking-tight text-center font-bold text-indigo-600 lg:text-4xl xl:text-4xl">
                        Hulp bij schulden.
                    </h1>
                    <br />
                    <p>
                        Als u uw rekeningen niet meer kunt betalen, kunnen de problemen zich snel opstapelen.
                        <br />
                        u krijgt aanmaningen of er staat een deurwaarder op de stoep.
                        <br />
                        Misschien piekert u over geld.
                    </p>
                    <br />
                    <p>
                        Als uw financiële problemen u teveel worden, dan kunt u om hulp vragen.

                        u kunt dan terecht bij ons!
                    </p>
                </div>
            </div>

            <div class="bg-white" id="clientBox">
                <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
                    <div class="max-w-3xl mx-auto text-center">
                        <h2 class="text-2xl tracking-tight text-center font-bold text-indigo-600 lg:text-4xl xl:text-4xl">
                            Clienten</h2>
                        <p class="mt-4 text-lg text-gray-500">Wij hebben klanten in diverse sectoren.</p>
                    </div>
                    <dl class="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
                        <div class="relative">
                            <dt>
                                <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                                <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Hoveniers</p>
                            </dt>
                        </div>
                        <div class="relative">
                            <dt>
                                <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                                <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Horeca</p>
                            </dt>
                        </div>
                        <div class="relative">
                            <dt>
                                <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                                <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Bouw bedrijfen</p>
                            </dt>
                        </div>
                        <div class="relative">
                            <dt>
                                <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                                <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Dierenartsen</p>
                            </dt>
                        </div>
                        <div class="relative">
                            <dt>
                                <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                                <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Entertainment</p>
                            </dt>
                        </div>
                        <div class="relative">
                            <dt>
                                <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                                <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Web Designers</p>
                            </dt>
                        </div>
                        <div class="relative">
                            <dt>
                                <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                                <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Car Service</p>
                            </dt>
                        </div>
                        <div class="relative">
                            <dt>
                                <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                </svg>
                                <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Zorg</p>
                            </dt>
                        </div>
                    </dl>
                </div>
            </div>
            <div class="bg-white">
                <div class="max-w-md mx-auto py-12 px-4 sm:max-w-3xl sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8" id="contactBox">
                    <div class="">
                        <div class="max-w-3xl py-5 mx-auto text-center">
                            <h2 class="text-2xl tracking-tight text-center font-bold text-indigo-600 lg:text-4xl xl:text-4xl">
                                Neem contact met ons op.</h2>
                            <p class="mt-4 text-lg text-gray-500">u kunt altijd e-mailen of bellen naar hier onder aangegeven mogelijkheden.</p>
                        </div>
                        <section class="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-2 text-center" aria-labelledby="contact-heading">
                            <div
                                class="mt-8 grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-y-6 lg:mt-0 lg:col-span-3">
                                <div>
                                    <h3 class="text-lg font-medium text-warm-gray-900">Adres</h3>
                                    <dl class="mt-2 text-base text-warm-gray-500">
                                        <div>
                                            <dt class="sr-only">Adres</dt>
                                            <dd>Leemwierde 162</dd>
                                            <dd>1353LS Almere </dd>
                                        </div>
                                    </dl>
                                </div>


                                <div>
                                    <h3 class="text-lg font-medium text-warm-gray-900">Email</h3>
                                    <dl class="mt-2 text-base text-warm-gray-500">
                                        <div>
                                            <dt class="sr-only">Email</dt>
                                            <dd>
                                                <a class="text-indigo-600 underline" href="mailto:p.overman7@kpnmail.nl"> p.overman7@kpnmail.nl </a>
                                            </dd>
                                        </div>
                                    </dl>
                                    <h3 class="text-lg font-medium text-warm-gray-900">KvK</h3>
                                    <dl class="mt-2 text-base text-warm-gray-500">
                                        <div>
                                            <dt class="sr-only">KvK</dt>
                                            <dd>
                                                32160675
                                            </dd>
                                        </div>
                                    </dl>
                                </div>

                                <div>
                                    <h3 class="text-lg font-medium text-warm-gray-900">Telefoon</h3>
                                    <dl class="mt-2 text-base text-warm-gray-500">
                                        <div>
                                            <dt class="sr-only">Huis Telefoon</dt>
                                            <dd>(036) 5489291</dd>
                                        </div>
                                        <div class="mt-1">
                                            <dt class="sr-only">Privé Telefoon</dt>
                                            <dd>(06) 51172661</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <footer class="bg-white">
                <div class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                    <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                        <div class="px-5 py-2">
                            <a href="#homeBox" class="text-base text-gray-500 hover:text-gray-900"> Home </a>
                        </div>

                        <div class="px-5 py-2">
                            <a href="#serviceBox" class="text-base text-gray-500 hover:text-gray-900"> Services </a>
                        </div>

                        <div class="px-5 py-2">
                            <a href="#clientBox" class="text-base text-gray-500 hover:text-gray-900"> Clienten </a>
                        </div>

                        <div class="px-5 py-2">
                            <a href="#contactBox" class="text-base text-gray-500 hover:text-gray-900"> Contact </a>
                        </div>
                    </nav>
                    <div class="mt-8 flex justify-center space-x-6">
                        <a href="https://www.facebook.com/ovetra.nl" class="text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Facebook</span>
                            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                    clip-rule="evenodd" />
                            </svg>
                        </a>
                    </div>
                    <div>

                    </div>
                    <p class="mt-8 text-center text-base text-gray-400">&copy; 2022 P. OvermanAdministratiekantoor B.V., All rights
                        reserved.</p>
                </div>
            </footer>

        </div>
    );
}

export default App;
